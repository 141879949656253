import { CompanyStatusGql } from '@generated';
import { AuthProvider } from 'src/environment/auth';
import { UUID } from 'src/types/basic';

export interface DebugAnalyticsConfig {
  logger: (...args: any[]) => void;
  prefix: string;
}

export interface AnalyticsProviderConfig {
  apiKey: string;
  apiHost: string;
}

export interface AnalyticsProviders {
  segment?: AnalyticsProviderConfig;
  posthog?: AnalyticsProviderConfig;
  amplitude?: AnalyticsProviderConfig;
  debug?: DebugAnalyticsConfig;
}

export type TrackEvent = string;

export interface AnalyticsProvider {
  /**
   * Identifies the user from the current session with an email or uuid
   * @param id - Unique identifier of a user
   * @param traits - This object describes properties of the current user like
   * email, name, etc
   */
  identify(
    id: string | undefined,
    traits?: Record<string, unknown>
  ): Promise<void> | void;

  /**
   * Registers an event
   * @param event - Key of given event
   * @param additionalData - Additional data about that specific event
   */
  track(
    event: TrackEvent,
    additionalData?: Record<string, unknown>
  ): Promise<void> | void;

  /**
   * Register current page as viewed
   * @param pageName - Name of the page
   * @param additionalData - Additional data about the page view
   */
  page(
    pageName: string,
    additionalData?: Record<string, unknown>
  ): Promise<void> | void;

  /**
   * Clear localstorage analytics data
   */
  reset(): void;

  alias: (aliasId: string) => void;
}

export enum SignedUpVia {
  SignUp = 'Sign Up',
}

export interface SignUpTrackingData {
  signed_up_via: SignedUpVia;
  has_options?: boolean;
  has_shares?: boolean;
  company_id?: UUID;
  company_name?: string;
  signed_up_with?: string;
  company_status?: CompanyStatusGql;
}

export interface LoginTrackingData {
  provider: AuthProvider;
}
