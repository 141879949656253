import {
  IParseOptions,
  IStringifyOptions,
  parse as qsParse,
  stringify as qsStringify,
} from 'qs';

const qsDecoder = (str: string) => {
  const strWithoutPlus = str.replace(/\+/g, ' ');

  const keywords: { [key: string]: any } = {
    false: false,
    null: null,
    true: true,
    undefined: undefined,
  };

  if (str in keywords) {
    return keywords[str];
  }

  const numberValue = parseFloat(str);
  if (String(numberValue) === str) {
    return numberValue;
  }

  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
};

const parse = (queryString: string, options: IParseOptions = {}) =>
  qsParse(queryString, {
    decoder: qsDecoder,
    ignoreQueryPrefix: true,
    ...options,
  });

const stringify = (queryObject: any, options: IStringifyOptions = {}) =>
  qsStringify(queryObject, {
    allowDots: true,
    indices: false,
    skipNulls: true,
    ...options,
  });

export const query = { parse, stringify };
