import React, { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { analytics } from 'src/services/analytics/analyticsService';
import { query } from 'src/utils/query';

export const CallbackRoute: FC = () => {
  const { pathname, search = '' } = useLocation();
  const [hasToRedirect, setHasToRedirect] = useState(false);
  const queryParams = query.parse(search);

  useEffect(() => {
    setTimeout(() => setHasToRedirect(true), 1000);

    if (pathname?.includes('/docusign')) {
      analytics.page('Callback - Docusign', {});
    } else {
      analytics.page('Callback', {});
    }
  }, []);

  if (!Object.keys(queryParams).length) {
    return null;
  }

  const { status, callbackUrl } = queryParams as {
    status: string;
    callbackUrl: string;
  };
  const callbackAppend = status ? `&docusign_status=${status}` : '';
  const redirectUri = `${callbackUrl}${callbackAppend}`;

  return (
    <Routes>
      {hasToRedirect && (
        <Route path="*" element={<Navigate to={redirectUri} />} />
      )}
    </Routes>
  );
};
